<template>
  <div>
    <div v-if="totalElements != null" class="md-layout-item md-size-100 total">
      <span> {{ $t("total_elements") }}: {{ totalElements }}</span>
    </div>

    <div class="md-layout md-alignment-center-center">
      <!-- BTN First -->
      <md-button
        class="md-icon-button md-primary md-size-10"
        :disabled="disabled || currentValue === minValue"
        @click="first"
        title="First page"
      >
        <font-awesome-icon icon="chevron-left" />
        <font-awesome-icon icon="chevron-left" />
      </md-button>

      <!-- BTN previous -->
      <md-button
        class="md-icon-button md-primary md-size-10"
        :disabled="disabled || currentValue === minValue"
        @click="previous"
        title="Previous page"
      >
        <font-awesome-icon icon="chevron-left" />
      </md-button>
      <!-- <md-field
        class="md-layout-item md-size-5 md-medium-size-10 md-small-size-15"
      >
        <md-input
          v-model.number="pageNumberSelector"
          type="number"
          :disabled="disabled"
        />
      </md-field> -->

      <span
        class="
          text-center
          md-layout-item
          md-size-5
          md-medium-size-10
          md-small-size-15
          md-subheading
          no-seleccionable
        "
      >
        {{ pageNumberSelector }} / {{ maxValue }}</span
      >

      <!-- BTN next -->
      <md-button
        v-if="
          (canCreate && currentValue + incrementView < maxValue) || !canCreate
        "
        class="md-icon-button md-primary md-size-10"
        :disabled="
          disabled || (!canCreate && currentValue + incrementView >= maxValue)
        "
        @click="next"
        title="Next page"
      >
        <font-awesome-icon icon="chevron-right" />
      </md-button>

      <!-- BTN + -->
      <md-button
        v-if="canCreate && currentValue === maxValue"
        class="md-icon-button md-primary md-size-10"
        :disabled="
          disabled || (!canCreate && currentValue + incrementView >= maxValue)
        "
        @click="next"
        title="Add"
      >
        <font-awesome-icon icon="plus" />
      </md-button>

      <!-- BTN Last -->
      <md-button
        v-if="
          (canCreate && currentValue + incrementView < maxValue) || !canCreate
        "
        class="md-icon-button md-primary md-size-10"
        :disabled="
          disabled || (!canCreate && currentValue + incrementView >= maxValue)
        "
        @click="last"
        title="Last page"
      >
        <font-awesome-icon icon="chevron-right" />
        <font-awesome-icon icon="chevron-right" />
      </md-button>

      <md-field
        v-if="allowChangeLimitPage === true"
        class="md-layout-item md-size-10 md-small-size-90"
      >
        <label for="limitSize">{{ $t("limitPageSize") }}</label>
        <md-select
          v-model.number="limitSize"
          name="limitSize"
          :md-selected="paginationSizeChanged"
          :md-opened="paginationSizeSelectOpened"
          :disabled="disabled"
        >
          <md-option value="5">5</md-option>
          <md-option value="10">10</md-option>
          <md-option value="20">20</md-option>
          <md-option value="50">50</md-option>
        </md-select>
      </md-field>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Pagination",
  data() {
    return {
      limitSize: 20,
      tmpLimitSize: null, // guarda el cambio de valor cuando se abre y cierra el desplegable de limit, por problemas con :md-selected a la hora de saber realmente cuando cambia de valor (salta el evento también cuando se cambia a página anterior/siguiente)
      tmpSelectedPage: null, // guarda el cambio de valor cuando se abre y cierra el desplegable de página a mostrar, por problemas con :md-selected a la hora de saber realmente cuando cambia de valor (salta el evento también cuando se cambia a página anterior/siguiente)
      pageNumberSelector: 1,
      isPaginateEvent: false,
    };
  },

  props: {
    minValue: Number,
    maxValue: Number,
    currentValue: Number,
    totalElements: {
      default: null,
      type: Number,
    },
    // Incrementar número mostrado
    // por defecto no se incrementa
    incrementView: {
      default: 0,
      type: Number,
    },
    canCreate: Boolean,
    allowChangeLimitPage: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    first() {
      this.$emit("first");
      this.isPaginateEvent = true;
      this.pageNumberSelector = this.minValue + this.incrementView;
    },

    previous() {
      this.$emit("previous");
      this.isPaginateEvent = true;
      this.pageNumberSelector--;
    },

    last() {
      this.$emit("last");
      this.isPaginateEvent = true;
      this.pageNumberSelector = this.maxValue;
    },

    next() {
      if (this.canCreate && this.currentValue === this.maxValue) {
        this.$emit("create");
      } else {
        this.$emit("next");
      }
      this.isPaginateEvent = true;
      this.pageNumberSelector++;
    },

    paginationSizeChanged() {
      if (this.tmpLimitSize != this.limitSize) {
        this.$emit("paginationSizeChanged", this.limitSize);
        this.pageNumberSelector = this.minValue + this.incrementView;
      }
    },

    paginationSizeSelectOpened() {
      this.tmpLimitSize = this.limitSize;
    },

    /** @deprecated */
    paginationSelectedPageChanged() {
      if (
        this.tmpSelectedPage != this.pageNumberSelector &&
        !this.isPaginateEvent
      ) {
        this.$emit("paginationSelectedPageChanged", this.pageNumberSelector);
      }
      this.isPaginateEvent = false;
    },

    paginationPageSelectOpened() {
      this.tmpSelectedPage = this.pageNumberSelector;
    },

    onChangeInput: _.debounce(function () {
      if (this.pageNumberSelector !== "") {
        if (this.pageNumberSelector <= 0) this.pageNumberSelector = 1;
        if (this.pageNumberSelector >= this.maxValue)
          this.pageNumberSelector = this.maxValue;

        this.$emit("paginationSelectedPageChanged", this.pageNumberSelector);
        this.isPaginateEvent = false;
      }
    }, 300),
  },

  created() {
    this.tmpLimitSize = this.limitSize;
    this.tmpSelectedPage = this.pageNumberSelector;
  },

  watch: {
    currentValue: function () {
      this.pageNumberSelector = this.currentValue + 1;
    },

    pageNumberSelector: function () {
      this.onChangeInput();
    },
  },
};
</script>
<style scoped>
.total {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>