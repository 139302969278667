<template>
  <div v-if="analysis && analysis.numberOfElements > 0">
    <md-list>
      <md-list-item
        v-for="content in analysis.content"
        :key="content.id"
        :to="{ name: 'AnalysisDetails', params: { id: content.id } }"
      >
        <b class="analysis-name" v-if="content.name">{{ content.name }} </b>
        <span> {{ formatDateTime(content.createdDate) }}</span>
      </md-list-item>
    </md-list>

    <pagination
      :minValue="0"
      :incrementView="1"
      :currentValue="analysis.number"
      :maxValue="analysis.totalPages"
      @first="firstPage"
      @previous="previousPage"
      @next="nextPage"
      @last="lastPage"
      @paginationSizeChanged="paginationSizeChanged"
      @paginationSelectedPageChanged="paginationSelectedPageChanged"
    />
  </div>
  <div v-else>{{ $t("no_results") }}</div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import { ANALYSIS_PARAMS_UPDATE } from "../../store/actions.types";
import Pagination from "../utils/Pagination.vue";
export default {
  components: { Pagination },
  name: "AnalysisList",
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      analysis: "getAnalysis",
      params: "getAnalysisParams"
    }),
  },

  methods: {
    firstPage() {
      this.$store.dispatch(ANALYSIS_PARAMS_UPDATE, {
        page: 0,
        size: this.params.size,
        q: this.params.q
      });
    },

    nextPage() {
      this.$store.dispatch(ANALYSIS_PARAMS_UPDATE, {
        page: this.params.page+1,
        size: this.params.size,
        q: this.params.q
      });
    },

    previousPage() {
      this.$store.dispatch(ANALYSIS_PARAMS_UPDATE, {
        page: this.params.page-1,
        size: this.params.size,
        q: this.params.q
      });
    },

    lastPage() {
      this.$store.dispatch(ANALYSIS_PARAMS_UPDATE, {
        page: this.analysis.totalPages - 1,
        size: this.params.size,
        q: this.params.q
      });
    },

    paginationSizeChanged() {
      console.log("TODO: paginationSizeChanged");
    },

    paginationSelectedPageChanged() {
      console.log("TODO: paginationSelectedPageChanged");
    },
  },
};
</script>
<style scoped>
.md-list-item:nth-child(odd) {
  background: #f5f5f5;
}
.md-list-item {
  padding: 5px 0;
}
.analysis-name {
  padding-right: 5px;
}
</style>