<template>
  <div>
    <md-button @click="reload" class="md-raised md-icon-button mg-left-0">
      <font-awesome-icon icon="redo" />
    </md-button>
    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'AnalysisCreate' }"
    >
      <font-awesome-icon icon="plus" />
    </md-button>

    <md-button class="md-raised md-icon-button mg-left-0" @click="toggleSearch">
      <font-awesome-icon icon="search" />
    </md-button>

    <md-field v-if="searchVisible">
      <label>{{ $t("search") }}</label>
      <md-input ref="analysis-search" v-model="search" />
    </md-field>

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <analysis-content v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AnalysisContent from "../../components/analysis/AnalysisContent.vue";
import _ from "lodash";
import {
  ANALYSIS_LOAD,
  ANALYSIS_PARAMS_UPDATE,
  ANALYSIS_UNLOAD,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
export default {
  name: "Analysis",
  components: {
    AnalysisContent,
  },

  computed: {
    ...mapGetters({
      params: "getAnalysisParams",
    }),
  },

  data() {
    return {
      loading: true,
      searchVisible: false,
      search: "",
    };
  },

  watch: {
    search: function () {
      this.onSearchChange();
    },
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "analysis");
    this.$store.dispatch(ANALYSIS_LOAD).then(() => {
      this.loading = false;
    });
  },

  beforeDestroy() {
    this.$store.dispatch(ANALYSIS_UNLOAD);
  },

  methods: {
    async reload() {
      this.loading = true;
      await this.$store.dispatch(ANALYSIS_LOAD);
      this.loading = false;
    },

    onSearchChange: _.debounce(function () {
      this.$store.dispatch(ANALYSIS_PARAMS_UPDATE, {
        page: 0,
        size: this.params.size,
        q: this.search,
      });
    }, 500),

    toggleSearch() {
      this.searchVisible = !this.searchVisible;
      if (this.searchVisible) {
        this.$nextTick(() => { // Asegurarse que el componente está cargado
          this.$refs["analysis-search"].$el.focus();
        });
      }
    },
  },
};
</script>
